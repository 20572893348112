import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer'
const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill

const setScrollbarWidth = width =>
  document.documentElement.style.setProperty('--scrollbar-width', width)

const queryScrollbarWidth = () =>
  setScrollbarWidth(innerWidth - document.documentElement.clientWidth + 'px')

setScrollbarWidth('17px')
document.addEventListener('DOMContentLoaded', queryScrollbarWidth)
const resizeObserver = new ResizeObserver(queryScrollbarWidth)
resizeObserver.observe(document.documentElement)


// Horizontal scrolling
const setScrollbarHeight = height =>
  document.documentElement.style.setProperty('--scrollbar-height', height)

setScrollbarHeight('17px')
document.addEventListener('DOMContentLoaded', () => {
  const outer = document.createElement('div')
  const inner = document.createElement('div')
  outer.style.position = 'absolute'
  outer.style.top = '-10000px'
  outer.style.left = '-10000px'
  outer.style.width = '50px'
  outer.style.overflowX = 'scroll'
  inner.style.width = '100px'
  inner.style.height = '20px'
  outer.appendChild(inner)
  document.body.appendChild(outer)
  setScrollbarHeight(outer.offsetHeight - inner.offsetHeight + 'px')
  document.body.removeChild(outer)
})
